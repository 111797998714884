@font-face {
  font-family: "LINE Seed JP";
  font-weight: 800;
  font-display: swap;
  src: local("LINE Seed JP ExtraBold"),
    url(./woff2/LINESeedJP_OTF_Eb.woff2) format("woff2"),
    url(./woff/LINESeedJP_OTF_Eb.woff) format("woff");
}

@font-face {
  font-family: "LINE Seed JP";
  font-weight: 700;
  font-display: swap;
  src: local("LINE Seed JP Bold"),
    url(./woff2/LINESeedJP_OTF_Bd.woff2) format("woff2"),
    url(./woff/LINESeedJP_OTF_Bd.woff) format("woff");
}

@font-face {
  font-family: "LINE Seed JP";
  font-weight: 400;
  font-display: swap;
  src: local("LINE Seed JP Regular"),
    url(./woff2/LINESeedJP_OTF_Rg.woff2) format("woff2"),
    url(./woff/LINESeedJP_OTF_Rg.woff) format("woff");
}

@font-face {
  font-family: "LINE Seed JP";
  font-weight: 100;
  font-display: swap;
  src: local("LINE Seed JP Thin"),
    url(./woff2/LINESeedJP_OTF_Th.woff2) format("woff2"),
    url(./woff/LINESeedJP_OTF_Th.woff) format("woff");
}
